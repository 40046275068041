import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import SurveyBuilder from "../components/survey-builder";

export default () => {
  const onChange = result => {
    console.log("JSON", result);
    console.log("JSON String", JSON.stringify(result));
  };
  return (
    <Page
      title={useTranslate("surveyNew")}
      description={useTranslate("surveyDescription")}
    >
      <SurveyBuilder onChange={onChange} />
    </Page>
  );
};
