import React from "react";
import styled from "styled-components";

const ErrorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: ${({ theme }) => theme.spacing.xl};
`;

const ErrorImage = styled.div`
	background-image: ${({ src }) => (src ? `url(${src})` : null)};
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 400px;
	height: 300px;
	max-width: 70%;
	max-height: 50%;
`;

const ErrorText = styled.div`
	margin-top: 3em;
	font-size: ${({ theme }) => theme.fontSize.xl};
	word-break: break-all;
	text-align: center;
`;

export default ({ error, graphic }) => (
	<ErrorWrapper>
		<ErrorImage
			src={graphic ? graphic : require("../../static/undraw/error.svg")}
		/>
		<ErrorText>{error}</ErrorText>
	</ErrorWrapper>
);
