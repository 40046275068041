import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import Error from "../components/error";
import Content from "../components/content";

export default ({
	match: {
		params: { id },
	},
	data: { loading, course, error },
}) => {
	const NotPublic = () => (
		<Error
			graphic={require("../static/undraw/notPublic.svg")}
			error={useTranslate("notPublic")}
		/>
	);
	const NotActive = () => (
		<Error
			graphic={require("../static/undraw/wait.svg")}
			error={useTranslate("notActiveYet")}
		/>
	);
	const NotFound = () => (
		<Error
			graphic={require("../static/undraw/courseNotFound.svg")}
			error={useTranslate("notFound")}
		/>
	);
	const renderComponent = course => {
		if (course) {
			if (!course.shared) {
				return <NotPublic />;
			} else {
				if (!course.active) {
					return <NotActive />;
				} else {
					return <Content>{useTranslate("sharedCourse")}</Content>;
				}
			}
		} else {
			return <NotFound />;
		}
	};
	return (
		<Page
			title={useTranslate("course")}
			description={useTranslate("courseDescription")}
			error={error && error.graphQLErrors[0].message}
			loading={loading}
		>
			{renderComponent(course)}
		</Page>
	);
};
