export default {
	login: "/login",
	register: "/register",
	demo: "/demo",
	dashboard: "/dashboard",
	module: "/module",
	course: "/module/course",
	survey: "/module/survey",
	event: "/module/event",
	meeting: "/module/meeting",
	profile: "/profile",
	imprint: "/imprint",
	policy: "/policy",
	system: "/system",
	sharedCourse: "/shared/course",
	sharedSurvey: "/shared/survey",
	sharedEvent: "/shared/event",
	sharedMeeting: "/shared/meeting",
};
