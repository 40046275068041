import React from "react";
import { dripForm } from "react-drip-form";
import {
	Form,
	CheckboxItem,
	RadioItem,
	FieldGroupItem,
	InputItem,
	TextareaItem,
} from "./components";

const SurveyForm = ({ handlers, meta: { invalid, pristine } }) => (
	<Form onSubmit={handlers.onSubmit}>
		{/* CheckboxItem with FieldGroup field */}
		text
		<FieldGroupItem name="library" multiple>
			<CheckboxItem value="react">React</CheckboxItem>
			<CheckboxItem value="angular">Angular</CheckboxItem>
			<CheckboxItem value="vue">Vue</CheckboxItem>
		</FieldGroupItem>
		text
		<FieldGroupItem name="demo">
			<RadioItem value="react">React</RadioItem>
			<RadioItem value="angular">Angular</RadioItem>
			<RadioItem value="vue">Vue</RadioItem>
		</FieldGroupItem>
		<InputItem name="name" />
		<TextareaItem name="title" />
		<FieldGroupItem name="confirm">
			<CheckboxItem value="yes">
				I agree to the <a href="/foo/bar/">Terms of Use</a>
			</CheckboxItem>
		</FieldGroupItem>
		<div>
			<button type="submit" disabled={pristine}>
				Submit!
			</button>
		</div>
	</Form>
);

export default dripForm({})(SurveyForm);
