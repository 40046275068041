import SurveyCreate from "../containers/surveyCreate";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const createSurvey = gql`
  mutation createSurvey(
    $name: String!
    $description: String
    $startDate: DateTime!
    $endDate: DateTime
    $data: String
    $password: String
    $users: [User!]!
  ) {
    createSurvey(
      name: $name
      description: $description
      startDate: $startDate
      endDate: $endDate
      data: $data
      password: $password
      users: $users
    ) {
      id
      name
      description
    }
  }
`;
export default graphql(createSurvey, { name: "createSurvey" })(SurveyCreate);
