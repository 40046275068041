import React from "react";
import styled from "styled-components";
import { useLanguage } from "../../../hooks/use-language";
import { FiX } from "react-icons/fi";

export const ElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 300px;
  min-width: ${({ open }) => (open ? `200px` : `0px`)};
  width: ${({ open }) => (open ? `100%` : `0px`)};
  transition: 0.5s width ease-in-out;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: ${({ theme }) => theme.shadow.baseOutset};
`;

const ElemenItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.lg};
  border-bottom: ${({ isDragging, theme }) =>
    isDragging ? null : `1px solid ${theme.color.gray}`};
  background: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme, isDragging }) =>
    isDragging ? theme.shadow.baseOutset : null};
`;

const ElemenItemIcon = styled.div`
  background: ${({ theme }) => theme.color.dark};
  border-radius: ${({ theme }) => theme.border.base};
  padding: ${({ theme }) => theme.spacing.base};
  height: ${({ theme }) => theme.fontSize.xl3};
  width: ${({ theme }) => theme.fontSize.xl3};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${({ theme, dir }) =>
    dir === "LTR" ? `0 ${theme.spacing.lg} 0 0` : `0 0 0 ${theme.spacing.lg}`};
  & > svg {
    color: ${({ theme }) => theme.color.white};
    stroke-width: 2;
    width: 100%;
    height: 100%;
  }
`;

const ElementItemTextWraper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ElemenItemTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const ElemenItemDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs1};
  font-weight: 300;
`;

export const ElementItem = ({ isDragging, item: { _type } }) => {
  const { dir } = useLanguage();
  return (
    <ElemenItemWrapper isDragging={isDragging}>
      <ElemenItemIcon dir={dir}>{_type.icon}</ElemenItemIcon>
      <ElementItemTextWraper>
        <ElemenItemTitle>{_type.title}</ElemenItemTitle>
        <ElemenItemDescription>{_type.description}</ElemenItemDescription>
      </ElementItemTextWraper>
    </ElemenItemWrapper>
  );
};

const ElementHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.lg};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.gray}`};
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.color.white};
`;

const ElementHeaderTitle = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

const ElementHeaderIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${({ dir, theme }) =>
    dir === "LTR" ? `0 0 0 ${theme.spacing.lg}` : `0 ${theme.spacing.lg} 0 0`};
  padding: ${({ theme }) => theme.spacing.sm};
  cursor: pointer;
  & < svg {
    width: 100%;
    height: 100%;
    stroke-width: 2;
  }
`;

export const ElementHeader = ({ title, onChangeOpen = () => {} }) => {
  const { dir } = useLanguage();
  return (
    <ElementHeaderWrapper>
      <ElementHeaderTitle>{title}</ElementHeaderTitle>
      <ElementHeaderIcon dir={dir} onClick={onChangeOpen}>
        <FiX />
      </ElementHeaderIcon>
    </ElementHeaderWrapper>
  );
};

export const ElementClone = styled.div`
  & ~ div {
    transform: none !important;
  }
  &:hover {
    box-shadow: none;
  }
`;
