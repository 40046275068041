import Meeting from "../containers/meeting";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
  query meetings {
    meetings {
      id
      createdAt
      updatedAt
      name
      description
    }
  }
`;

export default graphql(Query)(Meeting);
