import Register from "../containers/register";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const register = gql`
  mutation register(
    $email: String!
    $password: String!
    $firstname: String
    $lastname: String
  ) {
    register(
      email: $email
      password: $password
      firstname: $firstname
      lastname: $lastname
    ) {
      token
      user {
        id
        firstname
        lastname
        role
        organisation {
          id
          name
          description
          modules
        }
        language {
          id
          key
        }
      }
    }
  }
`;
export default graphql(register, { name: "registerMutation" })(Register);
