import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import Content from "../components/content";

export default () => {
	return (
		<Page
			title={useTranslate("imprint")}
			description={useTranslate("imprintDescription")}
		>
			<Content>{useTranslate("imprint")}</Content>
		</Page>
	);
};
