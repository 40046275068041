import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
//import { groupBy } from "../../../helpers/group-by";
import {
  ElementsWrapper,
  ElementHeader,
  ElementItem,
  ElementClone
} from "./elements";
import { reorder, copy } from "../functions";
import {
  BuilderWrapper,
  BuilderTitle,
  BuilderDescription,
  BuilderPage
} from "./builder";
import { useTranslate } from "../../../hooks/use-translate";

const SurveyBuilderWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SurveyBuilder = ({
  elements = [],
  survey: {
    title = "",
    description = "",
    pages = [{ title: "", description: "", elements: [] }]
  },
  onChange = () => {}
}) => {
  const [elementsOpen, setElementsOpen] = useState(true);
  const toggleElementsOpen = () => setElementsOpen(!elementsOpen);
  const [survey, setSurvey] = useState({ title, description, pages });
  const setInputValue = (key, value) => {
    setSurvey({ ...survey, [key]: value });
  };
  const onChangePage = (index, value) => {
    let surveyPages = survey.pages.slice();
    surveyPages[index] = value;
    setSurvey({
      ...survey,
      pages: surveyPages
    });
  };
  const updatePageElements = (pageIndex, elements) => {
    let pagesValue = survey.pages.slice();
    pagesValue[pageIndex].elements = elements;
    setInputValue("pages", pagesValue);
  };
  const addPage = () => {
    let pagesValue = survey.pages.slice();
    pagesValue.push({
      title: "",
      description: "",
      elements: []
    });
    setInputValue("pages", pagesValue);
  };
  useEffect(() => {
    onChange(survey);
  }, [survey]);
  const onDragEnd = result => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sourceMeta = {
      page: parseInt(source.droppableId.split("-")[1]),
      element: parseInt(source.droppableId.split("-")[3])
    };
    const destinationMeta = {
      page: parseInt(destination.droppableId.split("-")[1]),
      element: parseInt(destination.droppableId.split("-")[3])
    };
    switch (source.droppableId) {
      case destination.droppableId:
        updatePageElements(
          sourceMeta.page,
          reorder(
            survey.pages[sourceMeta.page].elements,
            source.index,
            destination.index
          )
        );
        break;
      case "elements":
        updatePageElements(
          destinationMeta.page,
          copy(
            elements,
            survey.pages[destinationMeta.page].elements,
            source,
            destination
          )
        );
        break;
      default:
        break;
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <SurveyBuilderWrapper>
        <BuilderWrapper>
          <BuilderTitle
            value={survey.title}
            placeholder={useTranslate("untitledForm")}
            onChange={e => setInputValue("title", e.target.value)}
          />
          <BuilderDescription
            value={survey.description}
            placeholder={useTranslate("addAShortDescription")}
            onChange={e => setInputValue("description", e.target.value)}
          />
          <div onClick={() => addPage()}>{useTranslate("addPage")}</div>
          {survey.pages.map((page, index) => (
            <BuilderPage
              key={index}
              index={index}
              {...page}
              onChange={p => onChangePage(index, p)}
            />
          ))}
        </BuilderWrapper>
        <ElementsWrapper open={elementsOpen}>
          <ElementHeader
            onChangeOpen={toggleElementsOpen}
            title={useTranslate("addElements")}
          />
          <Droppable droppableId="elements" isDropDisabled={true}>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {elements.map((item, index) => (
                  <Draggable
                    key={`element-item-${index}`}
                    draggableId={`element-item-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div>
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          isDragging={snapshot.isDragging}
                        >
                          <ElementClone>
                            <ElementItem
                              isDragging={snapshot.isDragging}
                              item={item}
                            />
                          </ElementClone>
                        </div>
                        {snapshot.isDragging && <ElementItem item={item} />}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </ElementsWrapper>
      </SurveyBuilderWrapper>
    </DragDropContext>
  );
};
