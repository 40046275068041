import { useContext } from "react";
import { LanguageContext } from "../containers/langRouter";

export const useLanguage = () => {
  const {
    languages,
    updateLang,
    language: { key, dir, id, in8 }
  } = useContext(LanguageContext) || {
    updateLang: () => {},
    language: { key: "", dir: "", id: "", in8: "" }
  };
  return { key, dir, id, in8, updateLang, languages };
};
