import React, { useState } from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import mutation from "../helpers/mutation";
import { useAuth } from "../hooks/use-auth";
import { useLanguage } from "../hooks/use-language";
import Form from "../components/form";
import styled from "styled-components";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const FormShadow = styled.div`
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadow.baseOutset};
  background-color: ${({ theme }) => theme.color.white};
`;

export default ({ signupUser }) => {
  const { login } = useAuth();
  const { updateLang } = useLanguage();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const signup = obj => {
    setLoading(true);
    mutation(signupUser, obj)
      .then(({ data: { signupUser } }) => {
        setLoading(false);
        login(signupUser);
        if (signupUser.language) {
          updateLang(signupUser.language.key);
        }
      })
      .catch(({ graphQLErrors }) => {
        setLoading(false);
        setError(graphQLErrors[0].functionError);
      });
  };

  return (
    <Page
      title={useTranslate("register")}
      description={useTranslate("registerDescription")}
      image={require("../static/images/login.jpg")}
    >
      <FormWrapper>
        <FormShadow>
          <Form
            loading={loading}
            error={error && useTranslate(error)}
            getChange={() => setError(null)}
            fields={[
              {
                placeholder: useTranslate("firstname"),
                type: "text",
                autocomplete: "firstname",
                name: "firstname",
                required: false
              },
              {
                placeholder: useTranslate("lastname"),
                type: "text",
                autocomplete: "lastname",
                name: "lastname",
                required: false
              },
              {
                placeholder: useTranslate("email"),
                type: "email",
                autocomplete: "username",
                name: "email",
                required: true
              },
              {
                placeholder: useTranslate("password"),
                type: "password",
                autocomplete: "password",
                name: "password",
                required: true
              }
            ]}
            submit={{ title: useTranslate("register"), action: signup }}
          />
        </FormShadow>
      </FormWrapper>
    </Page>
  );
};
