import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";

export default ({ data: { loading, survey, error } }) => {
  return (
    <Page
      title={useTranslate("survey")}
      description={useTranslate("surveyDescription")}
      loading={loading}
      error={error && error.graphQLErrors[0].message}
    >
      soon
    </Page>
  );
};
