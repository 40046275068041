import React from "react";
import { useTranslate } from "../../hooks/use-translate";
import {
  FiType,
  FiList,
  FiCheck,
  FiDisc,
  FiGrid,
  FiCalendar,
  FiHash
} from "react-icons/fi";

export default ({ children: Component, ...props }) => {
  const elements = [
    {
      section: useTranslate("elements"),
      type: "textField",
      _type: {
        title: useTranslate("textField"),
        description: useTranslate("singleLineOrMultilineTextArea"),
        icon: <FiType />
      }
    },
    {
      section: useTranslate("elements"),
      type: "selectFromList",
      _type: {
        title: useTranslate("selectFromList"),
        description: useTranslate("chooseAnOrMoreOptionsFromAList"),
        icon: <FiList />
      }
    },
    {
      section: useTranslate("elements"),
      type: "singleSelection",
      _type: {
        title: useTranslate("singleSelection"),
        description: useTranslate("selectOnlyOneItemWithARadioButton"),
        icon: <FiDisc />
      }
    },
    {
      section: useTranslate("elements"),
      type: "date",
      _type: {
        title: useTranslate("date"),
        description: useTranslate("selectDateFromADatepicker"),
        icon: <FiCalendar />
      }
    },
    {
      section: useTranslate("elements"),
      type: "multipleSelection",
      _type: {
        title: useTranslate("multipleSelection"),
        description: useTranslate("selectMultipleOptionsUsingACheckbox"),
        icon: <FiCheck />
      }
    },
    {
      section: useTranslate("elements"),
      type: "grid",
      _type: {
        title: useTranslate("grid"),
        description: useTranslate("selectOptionsFromAMatrix"),
        icon: <FiGrid />
      }
    },
    {
      section: useTranslate("elements"),
      type: "numeric",
      _type: {
        title: useTranslate("numeric"),
        description: useTranslate("itAcceptsOnlyNumbers"),
        icon: <FiHash />
      }
    }
  ];
  return <Component {...props} elements={elements} />;
};
