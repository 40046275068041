import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { useRoute } from "../../hooks/use-route";
import { useUser } from "../../hooks/use-user";
import { useTranslate } from "../../hooks/use-translate";
import Error from "../error";
import Page from "../page";

export const PublicRoute = ({ component: Component, path, exact }) => {
  const { token } = useUser();
  if (token) {
    return (
      <Redirect
        to={{
          pathname: useRoute("dashboard"),
          state: { from: path }
        }}
      />
    );
  } else {
    return <Route path={path} exact={exact} component={Component} />;
  }
};

export const ProtectedRoute = ({
  roles = [],
  component: Component,
  path,
  exact,
  module
}) => {
  const { token, user } = useUser();
  const isAllowed = user && user.role && roles.includes(user.role);
  if (token && isAllowed) {
    if (
      !module ||
      (module &&
        user.organisation &&
        user.organisation.modules.includes(module))
    ) {
      return <Route path={path} exact={exact} component={Component} />;
    } else {
      return (
        <Page
          title={useTranslate("Error")}
          description={useTranslate("moduleNotActivatedForOrganisation")}
        >
          <Error
            graphic={require("../../static/undraw/moduleNotFound.svg")}
            error={useTranslate("moduleNotActivatedForOrganisation")}
          />
        </Page>
      );
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: useRoute("login"),
          state: { from: path }
        }}
      />
    );
  }
};

export const RedirectStart = withRouter(({ location: { pathname }, path }) => {
  if (pathname) {
    return (
      <Redirect
        to={{
          pathname: useRoute("dashboard"),
          state: { from: path }
        }}
      />
    );
  } else {
    return null;
  }
});

export const SimpleRoute = props => <Route {...props} />;
