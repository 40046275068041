import CourseDetail from "../containers/courseDetail";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
  query course($id: ID) {
    course(where: { id: $id }) {
      id
      createdAt
      updatedAt
      name
      description
    }
  }
`;
const QueryParams = {
  options: props => ({
    variables: { id: props.match.params.id }
  })
};
export default graphql(Query, QueryParams)(CourseDetail);
