import { useContext } from "react";
import { AuthContext } from "../containers/langRouter";

export const useAuth = () => {
  const { login, logout } = useContext(AuthContext) || {
    logout: () => {},
    login: () => {}
  };
  return { login, logout };
};
