import Login from "../containers/login";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const login = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        firstname
        lastname
        role
        organisation {
          id
          name
          description
          modules
        }
        language {
          id
          key
        }
      }
    }
  }
`;
export default graphql(login, { name: "loginMutation" })(Login);
