import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import Error from "../components/error";
import SurveyForm from "../components/survey-form";

export default ({
	match: {
		params: { id },
	},
	data: { loading, survey, error },
}) => {
	const NotPublic = () => (
		<Error
			graphic={require("../static/undraw/notPublic.svg")}
			error={useTranslate("notPublic")}
		/>
	);
	const NotActive = () => (
		<Error
			graphic={require("../static/undraw/wait.svg")}
			error={useTranslate("notActiveYet")}
		/>
	);
	const NotFound = () => (
		<Error
			graphic={require("../static/undraw/surveyNotFound.svg")}
			error={useTranslate("notFound")}
		/>
	);
	const renderComponent = survey => {
		if (survey) {
			if (survey.state === "DRAFT") {
				return <NotPublic />;
			} else if (survey.state === "OPEN") {
				return (
					<SurveyForm
						onValidSubmit={console.log}
						data={JSON.parse(survey.data)}
					/>
				);
			} else if (survey.state === "CLOSED") {
				return <NotActive />;
			}
		} else {
			return <NotFound />;
		}
	};
	return (
		<Page
			title={useTranslate("survey")}
			description={useTranslate("surveyDescription")}
			error={error && error.graphQLErrors[0].message}
			loading={loading}
		>
			{renderComponent(survey)}
		</Page>
	);
};
