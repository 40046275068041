import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { useLanguage } from "../../hooks/use-language";
import Loading from "../loading";
import Error from "../error";

const PageWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	flex: 1;
	height: 100%;
`;

const Content = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	flex: 1;
	height: 100%;
	background-image: ${({ image }) => (image ? `url(${image})` : null)};
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	overflow-y: auto;
`;

export default ({ title, description, children, image, error, loading }) => {
	const { key, dir } = useLanguage();
	return (
		<PageWrapper>
			<Helmet htmlAttributes={{ lang: key, dir: dir }}>
				<title>{title}</title>
				<meta name="description" content={description} />
			</Helmet>
			{loading ? (
				<Loading />
			) : error ? (
				<Error error={error} />
			) : (
				<Content image={image}>{children}</Content>
			)}
		</PageWrapper>
	);
};
