import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslate } from "../../../hooks/use-translate";
import { Droppable, Draggable } from "react-beautiful-dnd";

export const BuilderWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  overflow-y: auto;
  max-height: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.base};
`;

export const BuilderTitle = styled.input`
  outline: none;
  padding: ${({ theme }) => theme.spacing.base};
  margin: 0 ${({ theme }) => theme.spacing.base};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  border: none;
`;

export const BuilderDescription = styled(BuilderTitle)`
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

const BuilderPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.base};
  padding: ${({ theme }) => theme.spacing.base};
  box-shadow: ${({ theme }) => theme.shadow.baseOutset};
`;

const BuilderPageTitle = styled.input`
  outline: none;
  border: none;
  padding: ${({ theme }) => theme.spacing.base};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

const BuilderPageDescription = styled(BuilderPageTitle)``;

const BuilderDroppableContent = styled.div`
  margin: ${({ theme }) => theme.spacing.base};
  border: 2px dashed ${({ theme }) => theme.color.grayLight};
  padding: ${({ theme }) => theme.spacing.xs};
  background: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
`;

const BuilderDroppablePlaceholder = styled.div`
  min-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

const BuilderElementDraggable = styled.div`
  margin: ${({ theme }) => theme.spacing.sm};
`;

const BuilderElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.sm};
  border: ${({ theme }) => `2px solid ${theme.color.grayLight}`};
`;

const BuilderElementQuestion = styled.input`
  padding: ${({ theme }) => theme.spacing.sm};
  outline: none;
  border: none;
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

const renderBuilderElement = (
  index,
  item,
  setElementOption,
  setElementQuestion,
  deleteElementItem
) => (
  <BuilderElementWrapper>
    <BuilderElementQuestion
      placeholder={useTranslate("enterQuestion")}
      value={item.question}
      onChange={e => setElementQuestion(index, e.target.value)}
    />
  </BuilderElementWrapper>
);

export const BuilderPage = ({
  title = "",
  description = "",
  elements = [],
  index = 0,
  onChange = () => {}
}) => {
  const [pageState, setPageState] = useState({ title, description, elements });
  const setInputValue = (key, value) => {
    setPageState({ ...pageState, [key]: value });
  };
  const setElementOption = (index, option) => {
    let tempPageState = Object.assign({}, pageState);
    tempPageState.elements[index].option = option;
    setPageState({ ...tempPageState });
  };
  const setElementQuestion = (index, question) => {
    let tempPageState = Object.assign({}, pageState);
    tempPageState.elements[index].question = question;
    setPageState({ ...tempPageState });
  };
  const deleteElementItem = index => {
    let tempPageState = Object.assign({}, pageState);
    tempPageState.elements.splice(index, 1);
    setPageState({ ...tempPageState });
  };
  useEffect(() => {
    onChange(pageState);
  }, [pageState]);
  const pageIndex = index;
  return (
    <BuilderPageWrapper>
      <BuilderPageTitle
        value={pageState.title}
        placeholder={useTranslate("untitledPage")}
        onChange={e => setInputValue("title", e.target.value)}
      />
      <BuilderPageDescription
        value={pageState.description}
        placeholder={useTranslate("addShortDescriptionForPage")}
        onChange={e => setInputValue("description", e.target.value)}
      />
      <Droppable droppableId={`page-${pageIndex}`}>
        {(provided, snapshot) => (
          <BuilderDroppableContent>
            <div
              ref={provided.innerRef}
              open={snapshot.isDraggingOver}
              {...provided.droppableProps}
            >
              {pageState.elements &&
                Boolean(pageState.elements.length) &&
                pageState.elements.map((item, index) => (
                  <Draggable
                    key={index}
                    draggableId={`page-${pageIndex}-element-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div>
                        <BuilderElementDraggable
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                        >
                          {renderBuilderElement(
                            index,
                            (item = { qurestion: "" }),
                            setElementOption,
                            setElementQuestion,
                            deleteElementItem
                          )}
                        </BuilderElementDraggable>
                      </div>
                    )}
                  </Draggable>
                ))}
              {pageState.elements &&
                !Boolean(pageState.elements.length) &&
                !snapshot.isDraggingOver && (
                  <BuilderDroppablePlaceholder>
                    {useTranslate("addElements")}
                  </BuilderDroppablePlaceholder>
                )}
            </div>
            {provided.placeholder}
          </BuilderDroppableContent>
        )}
      </Droppable>
    </BuilderPageWrapper>
  );
};
