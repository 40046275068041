import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Grid from "styled-components-grid";
import { Link } from "react-router-dom";
import { useTranslate } from "../../hooks/use-translate";
import { useLanguage } from "../../hooks/use-language";
import { useRoute } from "../../hooks/use-route";
import Moment from "react-moment";
import { FormattedNumber } from "react-intl";

const ListItemLink = styled(Link)`
  margin-bottom: ${({ theme }) => theme.spacing.base};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  color: unset;
  text-decoration: none;
  transition: 150ms box-shadow ease-in-out;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.baseOutset};
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;

const ListItemWrapper = styled(Grid)`
  padding: ${({ theme }) => theme.spacing.base};
`;

const ListItemSection = styled(Grid.Unit)``;

const ListItemIntro = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  ${breakpoint("md")`
		align-items: flex-start;
	`}
`;

const ListItemStats = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding-top: ${({ theme }) => theme.spacing.xl};
  ${breakpoint("sm")`
		flex-direction: row;
	`}
  ${breakpoint("md")`
		padding-top: 0px;
	`}
`;

const ListItemStatsItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.base}`};
  border-top: ${({ theme, dir }) =>
    dir === "LTR" ? `1px solid ${theme.color.grayLight}` : null};
  &:nth-child(1) {
    border-top: ${({ dir }) => (dir === "LTR" ? `0px` : null)};
  }
  ${breakpoint("sm")`
		padding: ${({ theme }) => `0 ${theme.spacing.base}`};
		border-top: 0px;
		border-left: ${({ theme, dir }) =>
      dir === "LTR" ? `1px solid ${theme.color.grayLight}` : null};
		border-right: ${({ theme, dir }) =>
      dir === "RTL" ? `1px solid ${theme.color.grayLight}` : null};
		&:nth-child(1) {
			border-left: ${({ dir }) => (dir === "LTR" ? `0px` : null)};
			border-right: ${({ dir }) => (dir === "RTL" ? `0px` : null)};
		}
	`}
`;

const ListItemStatsHeadline = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.base};
`;

const ListItemStatsSubline = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs1};
`;

const ListItemIntroSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.base};
`;

const ListItemState = styled.div`
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme, draft, open, closed }) =>
    draft
      ? theme.color.orange
      : open
      ? theme.color.green
      : closed
      ? theme.color.red
      : null};
  font-size: ${({ theme }) => theme.fontSize.xs1};
  text-transform: uppercase;
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  margin: ${({ theme, dir }) =>
    dir === "LTR"
      ? `0 ${theme.spacing.base} 0 0`
      : `0 0 0 ${theme.spacing.base}`};
`;

const ListItemTitle = styled.div``;

const ListItemLastEvent = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.color.darkLight};
`;

export const SurveyListItem = ({
  id,
  title,
  description,
  startDate,
  endDate,
  draft,
  open,
  closed,
  created,
  updated,
  replies
}) => {
  const { dir, in8 } = useLanguage();
  const lastReply =
    replies.length >= 1
      ? replies.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
          .createdAt
      : null;
  return (
    <ListItemLink to={`${useRoute("survey")}/${id}`}>
      <ListItemWrapper>
        <ListItemSection size={{ xs: 1, md: 1 / 2 }}>
          <ListItemIntro>
            <ListItemTitle>{title}</ListItemTitle>
            <ListItemIntroSection>
              {(draft || open || closed) && (
                <ListItemState
                  dir={dir}
                  draft={draft}
                  open={open}
                  closed={closed}
                >
                  {draft
                    ? useTranslate("draft")
                    : open
                    ? useTranslate("open")
                    : closed
                    ? useTranslate("closed")
                    : null}
                </ListItemState>
              )}
              <ListItemLastEvent>
                {draft || closed ? (
                  <span>
                    {`${useTranslate("lastUpdated")}: `}
                    <Moment locale={in8} fromNow interval={1000}>
                      {updated}
                    </Moment>
                  </span>
                ) : open ? (
                  <span>
                    {`${useTranslate("lastReply")}: `}
                    {lastReply ? (
                      <Moment locale={in8} fromNow interval={1000}>
                        {lastReply}
                      </Moment>
                    ) : (
                      useTranslate("never")
                    )}
                  </span>
                ) : null}
              </ListItemLastEvent>
            </ListItemIntroSection>
          </ListItemIntro>
        </ListItemSection>
        <ListItemSection size={{ xs: 1, md: 1 / 2 }}>
          {open && (
            <ListItemStats>
              <ListItemStatsItem dir={dir}>
                <ListItemStatsHeadline>
                  <FormattedNumber value={replies.length} />
                </ListItemStatsHeadline>
                <ListItemStatsSubline>
                  {useTranslate("replies")}
                </ListItemStatsSubline>
              </ListItemStatsItem>
              <ListItemStatsItem dir={dir}>
                <ListItemStatsHeadline>
                  <Moment locale={in8} fromNow interval={1000}>
                    {endDate}
                  </Moment>
                </ListItemStatsHeadline>
                <ListItemStatsSubline>
                  {useTranslate("endsIn")}
                </ListItemStatsSubline>
              </ListItemStatsItem>
            </ListItemStats>
          )}
        </ListItemSection>
      </ListItemWrapper>
    </ListItemLink>
  );
};

export default styled.div`
  display: flex;
  flex-direction: column;
`;
