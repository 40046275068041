export default {
	borderRadius: {
		xs: "2px",
		sm: "4px",
		base: "6px",
		lg: "8px",
		xl: "10px",
	},
	border: {
		xs: "1px",
		sm: "2px",
		base: "4px",
		lg: "6px",
		xl: "8px",
	},
	spacing: {
		xs: ".15rem",
		sm: ".25rem",
		base: ".5rem",
		lg: ".75rem",
		xl: "1rem",
		xl2: "1.25rem",
		xl3: "1.5rem",
		xl4: "1.875rem",
		xl5: "2.25rem",
	},
	shadow: {
		baseOutset: "rgba(0, 0, 0, 0.20) 1px 0px 20px 1px",
		baseInset: "inset rgba(0, 0, 0, 0.20) 1px 0px 20px 1px",
	},
	fontSize: {
		default: "20px",
		xs1: ".5rem",
		xs: ".75rem",
		sm: ".875rem",
		base: "1rem",
		lg: "1.125rem",
		xl: "1.25rem",
		xl2: "1.5rem",
		xl3: "1.875rem",
		xl4: "2.25rem",
		xl5: "3rem",
	},
	color: {
		white: "#fff",
		blue: "#538cd3",
		blueLight: "#b0e5ed",
		dark: "#364658",
		darkLight: "#6a7582",
		red: "#f44336",
		redLight: "#f7796f",
		gray: "#dde5ec",
		grayLight: "#e8eef2",
		green: "#4caf50",
		greenLight: "#8ed0a2",
		orange: "#f29957",
		orangeLight: "#f9cc8a",
		pinkLight: "#f7c9cc",
		opacity: "#25282ab3",
	},
	breakpoints: { xs: 0, sm: 600, md: 768, lg: 992, xl: 1200 },
};
