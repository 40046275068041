import React, { useEffect } from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import Meeting from "../components/meeting";

export default ({
	match: {
		params: { id },
	},
	data: { loading, meeting, error },
	updateMeeting,
}) => {
	const update = obj => {
		updateMeeting({
			variables: { id, active: obj.active, public: obj.public },
		}).then(console.log);
	};
	const renderMeeting = meeting => {
		if (!meeting) {
			return <NoResult />;
		} else if (meeting) {
			return <MeetingDetails />;
		}
	};
	const NoResult = () => <div>No Result</div>;
	const MeetingDetails = () => (
		<Meeting roomname={meeting.id} update={update} />
	);
	useEffect(() => {
		update({ active: true });
		window.addEventListener("beforeunload", () => {
			update({ active: false });
			console.log("set active to false");
		});
		return () => {
			update({ active: false });
			console.log("set active to false");
		};
	}, []);
	return (
		<Page
			title={useTranslate("meeting")}
			description={useTranslate("meetingDescription")}
			loading={loading}
			error={error && error.graphQLErrors[0].message}
		>
			{renderMeeting(meeting)}
		</Page>
	);
};
