import SurveyDetail from "../containers/surveyDetail";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
  query survey($id: ID) {
    survey(where: { id: $id }) {
      id
      createdAt
      updatedAt
      name
      description
      startDate
      endDate
      password
      data
      users {
        id
      }
      owner {
        id
      }
      organisation {
        id
      }
      replies {
        id
      }
      state
    }
  }
`;
const QueryParams = {
  options: props => ({
    variables: { id: props.match.params.id }
  })
};
export default graphql(Query, QueryParams)(SurveyDetail);
