import LangRouter from "../containers/langRouter";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
  query language($lng: String) {
    language(where: { key: $lng }) {
      id
      fallback
      flag
      dir
      key
      name
      in8
      translations {
        id
        key {
          id
          key
        }
        value
      }
    }
    me {
      id
      firstname
      lastname
      role
      organisation {
        id
        name
        description
        modules
      }
      language {
        id
        key
      }
    }
  }
`;
const QueryParams = {
  options: props => ({
    variables: { lng: props.match.params.lng }
  })
};
export default graphql(Query, QueryParams)(LangRouter);
