import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import Content from "../components/content";

export default () => {
	return (
		<Page
			title={useTranslate("profile")}
			description={useTranslate("profileDescription")}
		>
			<Content>{useTranslate("profile")}</Content>
		</Page>
	);
};
