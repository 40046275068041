import React from "react";
import styled from "styled-components";
import {
	Checkbox,
	Radio,
	Input,
	Textarea,
	FieldGroup,
} from "react-drip-form-components";
//import breakpoint from "styled-components-breakpoint";
import { useLanguage } from "../../hooks/use-language";
import { FiCheck } from "react-icons/fi";

export const Form = styled.form`
	& {
		padding: ${({ theme }) => theme.spacing.sm};
	}
	& > * {
		margin: 0px !important;
		color: unset !important;
		display: flex !important;
		flex-direction: column;
	}
`;

const CheckboxOutline = styled.div`
	height: 1em;
	width: 1em;
	min-width: 1em;
	min-height: 1em;
	border: 2px solid ${({ theme }) => theme.color.gray};
	border-radius: 2px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	margin: ${({ dir, theme }) =>
		dir === "LTR"
			? `0 ${theme.spacing.base} 0 0`
			: `0 0 0 ${theme.spacing.base}`};
`;

const CheckboxInline = styled.div`
	height: 80%;
	width: 80%;
	opacity: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	& > svg {
		width: 100%;
		height: 100%;
		stroke-width: 5px;
	}
`;

const CheckboxText = styled.div`
	display: block;
	font-size: ${({ theme }) => theme.fontSize.sm};
`;

const CheckboxWrapper = styled(Checkbox)`
	& {
		display: none;
	}
	& ~ span {
		border: 2px solid ${({ theme }) => theme.color.gray};
		display: flex;
		flex-direction: row;
		padding: ${({ theme }) => theme.spacing.base};
		align-items: center;
	}
	&:checked ~ span,
	&:checked ~ span > div:first-child,
	&:checked ~ span > div:first-child > div {
		color: ${({ theme }) => theme.color.blue};
		border-color: ${({ theme }) => theme.color.blue};
		opacity: 1;
	}
`;

export const CheckboxItem = ({ name, children, value }) => {
	const { dir } = useLanguage();
	return (
		<CheckboxWrapper name={name} value={`${value}`}>
			<CheckboxOutline dir={dir}>
				<CheckboxInline>
					<FiCheck />
				</CheckboxInline>
			</CheckboxOutline>
			<CheckboxText>{children}</CheckboxText>
		</CheckboxWrapper>
	);
};

export const FieldGroupItem = styled(FieldGroup)`
	& {
		display: flex !important;
		flex-direction: row !important;
		flex-wrap: wrap !important;
		box-sizing: border-box;
	}
	& > div {
		position: unset !important;
		margin: 0px !important;
		color: unset !important;
		padding: ${({ theme }) => theme.spacing.sm};
	}
`;

const RadioOutline = styled.div`
	height: 1em;
	width: 1em;
	min-width: 1em;
	min-height: 1em;
	border: 2px solid ${({ theme }) => theme.color.gray};
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	margin: ${({ dir, theme }) =>
		dir === "LTR"
			? `0 ${theme.spacing.base} 0 0`
			: `0 0 0 ${theme.spacing.base}`};
`;

const RadioInline = styled.div`
	height: 60%;
	width: 60%;
	border-radius: 50%;
	background: ${({ theme }) => theme.color.blue};
	opacity: 0;
`;

const RadioText = styled.div`
	display: block;
	font-size: ${({ theme }) => theme.fontSize.sm};
`;

const RadioWrapper = styled(Radio)`
	& {
		display: none;
	}
	& ~ span {
		border: 2px solid ${({ theme }) => theme.color.gray};
		display: flex;
		flex-direction: row;
		padding: ${({ theme }) => theme.spacing.base};
		align-items: center;
	}
	&:checked ~ span,
	&:checked ~ span > div:first-child,
	&:checked ~ span > div:first-child > div {
		color: ${({ theme }) => theme.color.blue};
		border-color: ${({ theme }) => theme.color.blue};
		opacity: 1;
	}
`;

export const RadioItem = ({ children, value }) => {
	const { dir } = useLanguage();
	return (
		<RadioWrapper value={`${value}`}>
			<RadioOutline dir={dir}>
				<RadioInline />
			</RadioOutline>
			<RadioText>{children}</RadioText>
		</RadioWrapper>
	);
};

const InputWrapper = styled(Input)`
	& {
		margin: ${({ theme }) => theme.spacing.sm} !important;
		border-radius: 0px !important;
		border-color: ${({ theme }) => theme.color.gray} !important;
		border-width: 2px !important;
		outline: none !important;
		padding: ${({ theme }) => theme.spacing.base} !important;
		width: unset !important;
		flex: 1 !important;
	}
`;

export const InputItem = ({ ...props }) => <InputWrapper {...props} />;

const TextareaWrapper = styled(Textarea)`
	& {
		margin: ${({ theme }) => theme.spacing.sm} !important;
		border-radius: 0px !important;
		border-color: ${({ theme }) => theme.color.gray} !important;
		border-width: 2px !important;
		outline: none !important;
		padding: ${({ theme }) => theme.spacing.base} !important;
		width: unset !important;
		flex: 1 !important;
	}
`;

export const TextareaItem = ({ ...props }) => <TextareaWrapper {...props} />;
