import SharedMeeting from "../containers/sharedMeeting";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
  query meeting($id: ID) {
    meeting(where: { id: $id }) {
      id
      createdAt
      updatedAt
      name
      description
      public
      password
      active
      moderator {
        id
        firstname
        lastname
      }
      participants {
        id
        firstname
        lastname
      }
    }
  }
`;
export const sharedMeetingSubscription = gql`
  subscription($id: ID) {
    meeting(filter: { node: { id: $id } }) {
      node {
        id
        createdAt
        updatedAt
        name
        description
        public
        password
        active
        moderator {
          id
          firstname
          lastname
        }
        participants {
          id
          firstname
          lastname
        }
      }
      updatedFields
    }
  }
`;
const QueryParams = {
  options: props => ({
    variables: { id: props.match.params.id }
  })
};
export default graphql(Query, QueryParams)(SharedMeeting);
