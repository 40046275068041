import Course from "../containers/course";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
  query courses {
    courses {
      id
      createdAt
      updatedAt
      name
      description
    }
  }
`;
export default graphql(Query)(Course);
