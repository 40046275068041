import React from "react";
import { useTranslate } from "../hooks/use-translate";
import { useRoute } from "../hooks/use-route";
import Page from "../components/page";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0.75em 1em;
`;

export const ListItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0.75em;
	background: white;
	margin: 0.25em 0;
`;

export const ListItemName = styled.div``;

export const ListItemDescription = styled.div`
	font-weight: 400;
	border-top: 1px solid ${({ theme }) => theme.color.grayLight};
	margin: 0.75em 0 0 0;
	padding: 0.75em 0 0 0;
	font-size: 0.8em;
`;

export const ListItemNameLink = styled(Link)`
	color: unset;
	text-decoration: unset;
`;

const Courses = ({ courses }) => {
	return (
		<ListWrapper>
			{courses.map(({ id, name, description }, index) => (
				<ListItem key={index}>
					<ListItemNameLink to={`${useRoute("course")}/${id}`}>
						<ListItemName>{name}</ListItemName>
					</ListItemNameLink>
					<ListItemDescription>{description}</ListItemDescription>
				</ListItem>
			))}
		</ListWrapper>
	);
};

export default ({ data: { loading, courses, error } }) => {
	return (
		<Page
			title={useTranslate("course")}
			description={useTranslate("courseDescription")}
			loading={loading}
			error={error && error.graphQLErrors[0].message}
		>
			{courses && <Courses courses={courses} />}
		</Page>
	);
};
