import React from "react";
import Elements from "./elements";
import { SurveyBuilder } from "./components";

const ElementsWrapper = ({
  elements,
  survey = {},
  onChange = () => {},
  ...props
}) => {
  return (
    <SurveyBuilder
      {...props}
      elements={elements}
      survey={survey}
      onChange={onChange}
    />
  );
};

export default ({ ...props }) => {
  return (
    <Elements>
      {({ elements }) => <ElementsWrapper {...props} elements={elements} />}
    </Elements>
  );
};
