import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import Meeting from "../components/meeting";
import { sharedMeetingSubscription } from "../screens/sharedMeeting";
import Error from "../components/error";

export default ({
	match: {
		params: { id },
	},
	data: { loading, meeting, error, subscribeToMore },
}) => {
	const PublicMeeting = () => <Meeting roomname={meeting.id} />;
	const NotPublic = () => (
		<Error
			graphic={require("../static/undraw/notPublic.svg")}
			error={useTranslate("notPublic")}
		/>
	);
	const NotActive = () => (
		<Error
			graphic={require("../static/undraw/wait.svg")}
			error={useTranslate("notActiveYet")}
		/>
	);
	const NotFound = () => (
		<Error
			graphic={require("../static/undraw/meetingNotFound.svg")}
			error={useTranslate("notFound")}
		/>
	);
	const renderComponent = meeting => {
		if (meeting) {
			if (!meeting.public) {
				return <NotPublic />;
			} else {
				if (!meeting.active) {
					return <NotActive />;
				} else {
					return <PublicMeeting />;
				}
			}
		} else {
			return <NotFound />;
		}
	};
	subscribeToMore({
		document: sharedMeetingSubscription,
		variables: {
			id,
		},
		updateQuery: (prev, { subscriptionData }) => {
			console.log("updateQuery", prev, subscriptionData);
			if (!subscriptionData.data.Meeting) {
				return prev;
			}
			return Object.assign(
				{},
				{ ...prev },
				{ ...subscriptionData.data.Meeting.node }
			);
		},
	});
	return (
		<Page
			title={
				meeting && meeting.name
					? `${useTranslate("meeting")}: ${meeting.name}`
					: useTranslate("meeting")
			}
			description={meeting && meeting.description}
			error={error && error.graphQLErrors[0].message}
			loading={loading}
		>
			{renderComponent(meeting)}
		</Page>
	);
};
