import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Link } from "react-router-dom";
import { useLanguage } from "../../hooks/use-language";

export const ContentTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xl2};
`;

export const ContentHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.base} 0`};
`;

const ContentHeaderLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ContentHeaderRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ContentHeader = ({ left, right }) => (
  <ContentHeaderWrapper>
    <ContentHeaderLeft>{left}</ContentHeaderLeft>
    <ContentHeaderRight>{right}</ContentHeaderRight>
  </ContentHeaderWrapper>
);

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing.base} 0`};
`;

const ContentLinkWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacing.base};
  font-size: ${({ theme }) => theme.fontSize.xs};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  text-decoration: none;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.baseOutset};
  }
`;

const ContentLinkIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${breakpoint("sm")`
    margin: ${({ dir, theme }) =>
      dir === "LTR"
        ? `0 ${theme.spacing.xs} 0 0`
        : `0 0 0 ${theme.spacing.xs}`};
  `}
`;
const ContentLinkTextWrapper = styled.div`
  display: none;
  ${breakpoint("sm")`
    display: block;
  `}
`;

export const ContentLink = ({ to, icon, text }) => {
  const { dir } = useLanguage();
  return (
    <ContentLinkWrapper to={to}>
      <ContentLinkIconWrapper dir={dir}>{icon}</ContentLinkIconWrapper>
      <ContentLinkTextWrapper>{text}</ContentLinkTextWrapper>
    </ContentLinkWrapper>
  );
};

export default styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.base};
  box-sizing: border-box;
`;
