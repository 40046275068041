import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { split, from } from "apollo-link";
import { onError } from "apollo-link-error";
import fetch from "unfetch";
import { GRAPHQL, WSS } from "../constants/server";
import user from "../helpers/user";
import storage from "../helpers/storage";
import { logout } from "../containers/langRouter";

const httpLink = createHttpLink({
	uri: GRAPHQL,
	fetch: fetch,
});

const wsLink = new WebSocketLink({
	uri: WSS,
	options: {
		lazy: true,
		reconnect: true,
		connectionParams: async () => {
			const { token } = user.getUser();
			const uuid = storage.get("uuid");
			return {
				headers: {
					authorization: token ? `Bearer ${token}` : "",
					uuid: uuid ? uuid : "",
				},
			};
		},
	},
});

const authLink = setContext((_, { headers }) => {
	const { token } = user.getUser();
	const uuid = storage.get("uuid");
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
			uuid: uuid ? uuid : "",
		},
	};
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		if (graphQLErrors[0].message === "jwt expired") {
			logout();
		}
	}
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = split(
	({ query }) => {
		const { kind, operation } = getMainDefinition(query);
		return kind === "OperationDefinition" && operation === "subscription";
	},
	wsLink,
	authLink.concat(httpLink)
);

const client = new ApolloClient({
	link: from([errorLink, link]),
	cache: new InMemoryCache(),
});

export default client;
