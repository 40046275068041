import React from "react";
import { useTranslate } from "../hooks/use-translate";
import { useRoute } from "../hooks/use-route";
import Page from "../components/page";
import Content, {
  ContentHeader,
  ContentBody,
  ContentLink,
  ContentTitle
} from "../components/content";
import SurveyList, { SurveyListItem } from "../components/survey-list";
import { FiPlus } from "react-icons/fi";

export default ({ data: { loading, surveys, error } }) => {
  return (
    <Page
      title={useTranslate("survey")}
      description={useTranslate("surveyDescription")}
      loading={loading}
      error={error && error.graphQLErrors[0].message}
    >
      <Content>
        <ContentHeader
          left={<ContentTitle>{useTranslate("survey")}</ContentTitle>}
          right={
            <ContentLink
              to={`${useRoute("survey")}/new`}
              icon={<FiPlus />}
              text={useTranslate("newSurvey")}
            />
          }
        />
        <ContentBody>
          <SurveyList>
            {surveys &&
              surveys.map(
                ({
                  id,
                  name,
                  description,
                  state,
                  startDate,
                  endDate,
                  createdAt,
                  updatedAt,
                  replies
                }) => (
                  <SurveyListItem
                    key={id}
                    id={id}
                    title={name}
                    description={description}
                    startDate={startDate}
                    endDate={endDate}
                    draft={Boolean(state === "DRAFT")}
                    open={Boolean(state === "OPEN")}
                    closed={Boolean(state === "CLOSED")}
                    created={createdAt}
                    updated={updatedAt}
                    replies={replies}
                  />
                )
              )}
          </SurveyList>
        </ContentBody>
      </Content>
    </Page>
  );
};
