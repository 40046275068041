import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import { Link } from "react-router-dom";
import { useRoute } from "../hooks/use-route";
import Content from "../components/content";

export default ({ data: { loading, meetings, error } }) => {
	const Meetings = () => (
		<Content>
			{meetings.map(({ id, name }, index) => (
				<div key={index}>
					<Link to={`${useRoute("meeting")}/${id}`}>{name}</Link>
				</div>
			))}
		</Content>
	);
	return (
		<Page
			title={useTranslate("meeting")}
			description={useTranslate("meetingDescription")}
			loading={loading}
			error={error && error.graphQLErrors[0].message}
		>
			{meetings && <Meetings />}
		</Page>
	);
};
