import Navigation from "../containers/navigation";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

const Query = gql`
  query languages {
    languages(where: { active: true }) {
      id
      fallback
      flag
      dir
      key
      name
    }
  }
`;
export default graphql(Query)(Navigation);
