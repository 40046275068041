import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import Content from "../components/content";

export default ({ data: { loading, course, error } }) => {
	const NoResult = () => <Content>No Result</Content>;
	const CourseDetails = () => (
		<Content>
			<div>{course.name}</div>
			<div>{course.description}</div>
		</Content>
	);
	return (
		<Page
			title={useTranslate("course")}
			description={useTranslate("courseDescription")}
			loading={loading}
			error={error && error.graphQLErrors[0].message}
		>
			{!course && <NoResult />}
			{course && <CourseDetails />}
		</Page>
	);
};
