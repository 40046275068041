import React, { createContext } from "react";
import { Switch } from "react-router-dom";
import Page from "../components/page";
import Error from "../components/error";
import Loading from "../components/loading";
import {
  PublicRoute,
  ProtectedRoute,
  RedirectStart,
  SimpleRoute
} from "../components/router";
import Login from "../screens/login";
import Register from "../screens/register";
import Dashboard from "../screens/dashboard";
import Profile from "../screens/profile";
import Meeting from "../screens/meeting";
import Course from "../screens/course";
import Event from "../screens/event";
import CourseDetail from "../screens/courseDetail";
import EventDetail from "../screens/eventDetail";
import MeetingDetail from "../screens/meetingDetail";
import SharedMeeting from "../screens/sharedMeeting";
import SharedSurvey from "../screens/sharedSurvey";
import Imprint from "../screens/imprint";
import Policy from "../screens/policy";
import System from "../screens/system";
import Survey from "../screens/survey";
import SurveyDetail from "../screens/surveyDetail";
import SharedCourse from "../screens/sharedCourse";
import SharedEvent from "../screens/sharedEvent";
import SurveyCreate from "../screens/surveyCreate";
import NavigationPage from "../components/navigation-page";

import { setLang } from "../helpers/language";
import user from "../helpers/user";
import routes from "../constants/routes";
import client from "../client/graphql";

import "moment/min/locales";
import { IntlProvider, addLocaleData } from "react-intl";
import reactIntlLocaleAT from "react-intl/locale-data/de";
import reactIntlLocaleEN from "react-intl/locale-data/en";
import reactIntlLocaleAR from "react-intl/locale-data/ar";
import reactIntlLocaleSW from "react-intl/locale-data/sw";
import reactIntlLocaleES from "react-intl/locale-data/es";
import reactIntlLocaleCN from "react-intl/locale-data/zh";

addLocaleData([
  ...reactIntlLocaleAT,
  ...reactIntlLocaleEN,
  ...reactIntlLocaleAR,
  ...reactIntlLocaleSW,
  ...reactIntlLocaleES,
  ...reactIntlLocaleCN
]);

export const LanguageContext = createContext(null);
export const UserContext = createContext(null);
export const RouteContext = createContext(null);
export const AuthContext = createContext(null);

export const logout = () => {
  client.clearStore().then(() => {
    client.resetStore();
    user.setUser({});
  });
};

export default ({
  data: { loading, language, me, error },
  history,
  location,
  match,
  languages
}) => {
  const login = usr => {
    user.setUser(usr);
    history.replace(`/`);
  };
  const restructTranslations = translations => {
    let res = {};
    translations &&
      translations.forEach(t => {
        res[t.key.key] = t.value;
      });
    return res;
  };
  const translate = key => {
    return (
      restructTranslations(language.translations)[key] ||
      `{{${key}_${language.key}}}`
    );
  };
  const updateLang = lang => {
    history.replace(location.pathname.replace(match.url, `/${lang}`));
  };
  if (loading) {
    return (
      <Page title="Loading">
        <Loading />
      </Page>
    );
  }
  if (error) {
    return (
      <Page title="Error">
        <Error error={error && error.graphQLErrors[0].message} />
      </Page>
    );
  }
  if (language) {
    setLang(language.key);
  }
  if (me) {
    user.extendUser(me);
    if (me.language && me.language.key !== language.key) {
      updateLang(me.language.key);
    }
  }
  return (
    <LanguageContext.Provider
      value={{
        language: {
          ...language,
          translations: restructTranslations(language.translations)
        },
        languages,
        translate,
        updateLang
      }}
    >
      <IntlProvider locale={language.in8}>
        <UserContext.Provider value={{ ...user.getUser() }}>
          <RouteContext.Provider value={routes}>
            <AuthContext.Provider value={{ logout, login }}>
              <NavigationPage>
                <Switch>
                  <PublicRoute
                    exact
                    path={`/${language.key}${routes.login}`}
                    component={Login}
                  />
                  <PublicRoute
                    exact
                    path={`/${language.key}${routes.register}`}
                    component={Register}
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.dashboard}`}
                    component={Dashboard}
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.course}`}
                    component={Course}
                    module="COURSE"
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.course}/:id`}
                    component={CourseDetail}
                    module="COURSE"
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.survey}`}
                    component={Survey}
                    module="SURVEY"
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.survey}/new`}
                    component={SurveyCreate}
                    module="SURVEY"
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.survey}/:id`}
                    component={SurveyDetail}
                    module="SURVEY"
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.meeting}`}
                    component={Meeting}
                    module="MEETING"
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.meeting}/:id`}
                    component={MeetingDetail}
                    module="MEETING"
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.event}`}
                    component={Event}
                    module="EVENT"
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.event}/:id`}
                    component={EventDetail}
                    module="EVENT"
                  />
                  <ProtectedRoute
                    exact
                    roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                    path={`/${language.key}${routes.profile}`}
                    component={Profile}
                  />
                  <SimpleRoute
                    exact
                    path={`/${language.key}${routes.imprint}`}
                    component={Imprint}
                  />
                  <SimpleRoute
                    exact
                    path={`/${language.key}${routes.policy}`}
                    component={Policy}
                  />
                  <SimpleRoute
                    exact
                    path={`/${language.key}${routes.system}`}
                    component={System}
                  />
                  <SimpleRoute
                    path={`/${language.key}${routes.sharedCourse}/:id`}
                    component={SharedCourse}
                  />
                  <SimpleRoute
                    path={`/${language.key}${routes.sharedSurvey}/:id`}
                    component={SharedSurvey}
                  />
                  <SimpleRoute
                    path={`/${language.key}${routes.sharedMeeting}/:id`}
                    component={SharedMeeting}
                  />
                  <SimpleRoute
                    path={`/${language.key}${routes.sharedEvent}/:id`}
                    component={SharedEvent}
                  />
                  <RedirectStart />
                </Switch>
              </NavigationPage>
            </AuthContext.Provider>
          </RouteContext.Provider>
        </UserContext.Provider>
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
