import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import { Link } from "react-router-dom";
import { useRoute } from "../hooks/use-route";
import Content from "../components/content";

export default ({ data: { loading, events, error } }) => {
	const Events = () => (
		<Content>
			{events.map(({ id, name }, index) => (
				<div key={index}>
					<Link to={`${useRoute("event")}/${id}`}>{name}</Link>
				</div>
			))}
		</Content>
	);
	return (
		<Page
			title={useTranslate("event")}
			description={useTranslate("eventDescription")}
			loading={loading}
			error={error && error.graphQLErrors[0].message}
		>
			{events && <Events />}
		</Page>
	);
};
