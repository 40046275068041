import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import Content from "../components/content";

export default ({ data: { loading, event, error } }) => {
	const NoResult = () => <Content>No Result</Content>;
	const EventDetails = () => (
		<Content>
			<div>{event.name}</div>
			<div>{event.description}</div>
		</Content>
	);
	return (
		<Page
			title={useTranslate("event")}
			description={useTranslate("eventDescription")}
			loading={loading}
			error={error && error.graphQLErrors[0].message}
		>
			{!event && <NoResult />}
			{event && <EventDetails />}
		</Page>
	);
};
