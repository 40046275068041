import storage from "./storage";

const getUser = () => {
  return storage.get("user") && storage.get("user", true).token
    ? storage.get("user", true)
    : {};
};

const setUser = user => {
  storage.set("user", user, true);
};

const extendUser = user => {
  const current = getUser();
  if (current.token) {
    storage.set("user", { ...current, user: user }, true);
  }
};

export default { getUser, setUser, extendUser };
