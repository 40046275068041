import Survey from "../containers/survey";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
  query surveys {
    surveys {
      id
      createdAt
      updatedAt
      name
      description
      startDate
      endDate
      password
      data
      users {
        id
      }
      owner {
        id
      }
      organisation {
        id
      }
      replies {
        id
        createdAt
      }
      state
    }
  }
`;
export default graphql(Query)(Survey);
