import { useContext } from "react";
import { RouteContext } from "../containers/langRouter";
import { useLanguage } from "./use-language";

export const useRoute = route => {
  const routes = useContext(RouteContext) || [];
  const { key } = useLanguage();
  const r = routes[route] || `{{${route}}}`;
  return `/${key}${r}`;
};
