import React, { useState } from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import mutation from "../helpers/mutation";
import { useLanguage } from "../hooks/use-language";
import { useAuth } from "../hooks/use-auth";
import Form from "../components/form";
import styled from "styled-components";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const FormShadow = styled.div`
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadow.baseOutset};
  background-color: ${({ theme }) => theme.color.white};
`;

export default ({ loginMutation }) => {
  const { login } = useAuth();
  const { updateLang } = useLanguage();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const authenticate = obj => {
    setLoading(true);
    mutation(loginMutation, obj)
      .then(({ data }) => {
        setLoading(false);
        login(data.login);
        if (data.login.language) {
          updateLang(data.login.language.key);
        }
      })
      .catch(({ graphQLErrors }) => {
        setLoading(false);
        setError(graphQLErrors[0].message);
      });
  };

  return (
    <Page
      title={useTranslate("login")}
      description={useTranslate("loginDescription")}
      image={require("../static/images/login.jpg")}
    >
      <FormWrapper>
        <FormShadow>
          <Form
            loading={loading}
            error={error && useTranslate(error)}
            getChange={() => setError(null)}
            fields={[
              {
                placeholder: useTranslate("email"),
                type: "email",
                autocomplete: "username",
                name: "email",
                required: true
              },
              {
                placeholder: useTranslate("password"),
                type: "password",
                autocomplete: "password",
                name: "password",
                required: true
              }
            ]}
            submit={{ title: useTranslate("login"), action: authenticate }}
          />
        </FormShadow>
      </FormWrapper>
    </Page>
  );
};
